.item-config:hover{
    background-color: #cd1009 !important;
    color: white !important;
    border-radius: 5px;
    cursor: pointer;
}
.item-config-active{
    background-color: #343843;
    border-radius: 5px;

}
.item-config{
    color:#a2a9b4 !important;
    font-family: 'Inter' !important;
}
.item-config-active{
  color: #fff !important;
  font-weight: 600;
}


@media (min-width: 1200px) and (max-width: 1439px) {
.menu-rankings{
    width: 60% !important;
}

}

@media (min-width: 1920px) {
    .menu-rankings{
        width: 35.1% !important;
    }
}


@media (max-width: 768px) {

    .table{
        width: 100% !important;
    }

    .menu-rankings {
        display: flex;
        overflow-x: auto;
        white-space: nowrap;
        width: 100%;
    }
    
    .item-config {
        flex: 0 0 auto; /* Prevent items from shrinking */
        padding: 10px;
        text-align: center;
        margin-right: 10px;
    }
    .item-config-active{
        flex: 0 0 auto; /* Prevent items from shrinking */
        padding: 10px;
        text-align: center;
        margin-right: 10px;
        background-color: #343843;
        font-weight: 600;
        border-radius: 5px;
    
    }
    
    
    
    /* Estilos para cada fila */
    tr {
        display: flex;
        align-items: center;
        margin-bottom: 0.5em;
    }
    
    /* Estilos para cada celda */
    td {
        display: block;
        text-align: right;
        position: relative;
        padding-left: 50%;
        white-space: pre-wrap;
    }
    
    /* Agregar un pseudo-elemento antes de cada celda para mostrar el nombre del encabezado */
    td::before {
        content: attr(data-label);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        left: 0;
        width: 100%;
        
        font-weight: bold;
        text-align: left;
    }
}


.menu-rankings::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  .menu-rankings::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  .menu-rankings::-webkit-scrollbar-thumb {
    background: #343843;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  .menu-rankings::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
  .menu-rankings::-webkit-scrollbar-thumb:active {
    background: #343843;
  }
  .menu-rankings::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  .menu-rankings::-webkit-scrollbar-track:hover {
    background: #666666;
  }
  .menu-rankings::-webkit-scrollbar-track:active {
    background: #333333;
  }
  .menu-rankings::-webkit-scrollbar-corner {
    background: transparent;
  }