/* Estilos para las filas pares */
.table tbody tr:nth-child(even) {
  background-color: #080808;
}

/* Estilos para las filas impares */
.table tbody tr:nth-child(odd) {
  background-color: #202329;
}

/* Estilos para la tabla */
.table {
  width: 70%;
  
}
thead{
  background-image: url('./noticias.gif');
  background-size: cover;
  background-position: center;
}

/* Estilos para las celdas */
.table th,
.table td {
  padding: 8px;
  text-align: center;
  border: none;
}

/* Estilos para la cabecera */
.table th {
  color: #837e7e;
}

.connected img,
.disconnected img {
  width: 30px;
  /* Tamaño del icono */
  height: 30px;
  border-radius: 50%;
  /* Para hacer un borde circular */
  padding: 2px;
  /* Espacio alrededor de la imagen */
}

.connected img {
  border: 2px solid green;
  /* Borde verde cuando está conectado */
}

.disconnected img {
  border: 2px solid grey;
  /* Borde gris cuando está desconectado */
}

.player-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  /* Alinear verticalmente los elementos */
}

.canvas-clan {
  border: 2px solid grey;
  /* Borde gris */
  display: inline-block;
  /* Cambia a un bloque en línea */
  vertical-align: middle;
  /* Alinea verticalmente */
  margin-right: 8px;
  /* Agrega un margen a la derecha para separar la imagen del nombre */
}

.clan-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  /* Alinear verticalmente los elementos */
}

