.banner1{

    background-image: url('./fondo.webp');
    background-size: cover;
    border-radius: 5px;
    height: 1000px !important;
    background-position: center;
}


.img-noticias{
    background-image: url('./noticias.gif');
    background-position: center;
    background-size: cover;
}
.banner2{
    background-image: url('./section_new_linemu.png');
}

.img-bannerPrincipal{
    position: absolute;
    width: 12%;
    top: 12.5%,;
    left: 51.1%; 
    transition: transform 0.3s ease, filter 0.3s ease;
}

.img-efecto:hover{
    transform: scale(1.05); /* Zoom */
     filter: brightness(100%); /* Restaurar brillo */     
     border-radius: 5px;
}



@media (max-width: 480px) {
    .img-bannerPrincipal{
        position: absolute;
        width: 50% !important;
        transition: transform 0.3s ease, filter 0.3s ease;
        left: 30% !important;
        top: 15% !important;
    }
  }
  @media (max-width: 768px){
    .banner1{
      background-image: url('./fondo.webp');
      background-size: cover;
      border-radius: 5px;
      height: 760px !important;
      background-position: 20%;
  }
  }

/* BackgroundVideo.css */
.background-video{
    width: 100%;
    height: 100%;
}
.video-background-container {
    position: relative;
    width: 100%;
    max-height: 760px;
    overflow: hidden;
  }
  
  .video-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -1; /* Ensure the video stays in the background */
    object-fit: cover; /* Cover the entire container */
  }
  
  .overlay-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    z-index: 1;
  }
  
  .overlay-content h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .overlay-content p {
    font-size: 1.5rem;
    max-width: 800px;
  }
  