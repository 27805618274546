.menu-aside-option {
    width: 100%;
    transition: all .2s ease-in-out;
    position: relative;
}

/* menu.css */
.mobile-menu {
    position: fixed;
    border-top: #2a2e38;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #121418;
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    z-index: 1000; /* Asegura que el menú esté por encima de otros elementos */
}

.mobile-menu a {
    color: white;
    text-decoration: none;
    font-size: 18px;
    
}

.mobile-menu a:hover {
    color: #ff0; /* Color al pasar el ratón */
}
.item-li {
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    transition: color .2s ease-in-out;
    animation: fadeIn 1.5s ease-in-out;

}
.item-hover:hover{
    color:#cd1009 !important;
    cursor:pointer;
}
.button-sports {
    animation: fadeIn 1.5s ease-in-out;
}
.button-sports:hover{
    background: url('./mobile-tab-background-press.svg') red;
}
/* Animación de entrada */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.pixel-li {
    padding: 1rem;
    border: 1px solid #2a2e38;
    border-right: none;
    border-left: none;
    border-top: none;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    animation: fadeIn 1.5s ease-in-out;

}
@keyframes shadowPulseAnimation {
    0% {
        box-shadow: 0 0 #00e70166;
    }

    30% {
        box-shadow: 0 0 2px 2px #00e70166
    }

    70% {
        box-shadow: 0 0 2px 2px #00e70166;
    }
}
.button-purchase-pixels {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    /* border: 1px solid #262626; */
    border: 1px solid #2a2e38;
    padding: 6.5px;
    height: 28px;
    border-radius: 5px ;
    width: 150px;
    margin: 0px;
    margin-top: 0px;
    color: #EFEFF1;
    text-transform: capitalize;
    cursor: pointer;
    letter-spacing: 0.5px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    transition: 200ms all;
  }
  .button-purchase-pixels:hover {
    background-color: #343843;
    border: 1px solid rgb(205, 16, 9);
    /* border: 1px solid #7717ff; */
  }
  
  .button-purchase-pixels>button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 1px solid #2a2e38;
    padding: 6.5px;
    height: 45px;
    border-radius: 3px;
    width: 250px;
    letter-spacing: 0.5px;
    font-family: "inter";
    font-weight: 500;
    border: none;
  }
  .boton-comprar:hover{
    background-color: #343843 !important;
    border: 1px solid rgb(205, 16, 9) !important;
  }
  .button-purchase-pixels>i {
    display: flex !important;
    width: 18px !important;
    height: 18px;
    text-align: center;
    border-radius: 10px;
    padding: 5px;
    font-size: 13px;
    cursor: pointer;
    transform: rotate(-90deg);
    align-content: center;
    align-items: center;
    justify-content: center;
    color: #594e99;
    animation: 1s;
  }
@keyframes shadowPulseAnimationOff {
    0% {
        box-shadow: 0 0 #cd100966;
    }

    30% {
        box-shadow: 0 0 2px 2px #cd100966
    }

    70% {
        box-shadow: 0 0 2px 2px #cd100966;
    }
}

#pulsatingDot {
    width: 7px;
    height: 7px;
    background-color: #1fff20;
    border-radius: 50%;
    margin-top: 2px;
    margin-left: 5px;
    margin-right: 7px;
    animation: shadowPulseAnimation 1s infinite ease-out;
}

#pulsatingDotOff {
    width: 7px;
    height: 7px;
    background-color: #cd1009;
    border-radius: 50%;
    margin-top: 2px;
    margin-left: 5px;
    margin-right: 7px;
    animation: shadowPulseAnimationOff 1s infinite ease-out;
}

.pixel-li:hover::after {
    background-color: #ed3d3d;
    position: absolute;
    content: '';
    height: 3rem;
    width: .25rem;
    opacity: 1;
    border-radius: 0 1.5rem 1.5rem 0;
    transition: opacity 0.3s ease;
    /* Transición suave */
    left: 0;
}

.item-li:hover {
    color: #ed3d3d !important;
    cursor: pointer;
}

.item-li::after {
    background-color: #ed3d3d;
    position: absolute;
    content: '';
    height: 3rem;
    width: .25rem;
    opacity: 0;
    border-radius: 0 1.5rem 1.5rem 0;
    transition: opacity 0.3s ease;
    /* Transición suave */
    left: 0;
    top: 0;
}
.expandedCartera{
    animation: bounce .1s both ;
}
.moneda:hover{
    background-color: #2a2e38;
    cursor: pointer;
}
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
    40% {transform: translateY(-5px);} 
    60% {transform: translateY(-5px);} 
 } 
.item-li:hover::after {
    opacity: 1;
    /* Mostrar el contenido al hacer hover */
}
.item-liActive {
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    transition: color .2s ease-in-out;
    animation: fadeIn 1.5s ease-in-out;
    color: #ed3d3d !important;
    cursor: pointer;
}
.item-liActive::after{
    background-color: #ed3d3d;
    position: absolute;
    content: '';
    height: 3rem;
    width: .25rem;
    opacity: 1;
    border-radius: 0 1.5rem 1.5rem 0;
    transition: opacity 0.3s ease;
    /* Transición suave */
    left: 0;
    top: 0;
}
.img-bars {
    transition: all .3s ease-in-out;
}

.img-bars:hover {
    fill: #ed3d3d !important;
    cursor: pointer;
}

.icon-hover:hover{
    transform: scale(1.1);
}

.button-casino{
    background:url("./BOTON-DIRECTO-blanco-y-negro.jpg") #19398a;
    background-size: cover;
    
}
.button-navbar-login {
    display: flex;
    align-items: center;
    text-shadow: rgb(0, 0, 0) 0px 1px 0px;
    justify-content: center;
    background-color: transparent;
    border: 1px solid rgb(42, 46, 56);
    background: rgb(42, 46, 56);
    text-shadow:'0 1px 0 #000';
    padding: 0px 1rem;
    height: 3rem;
    border-radius: 0.375rem;
    width: 100%;
    color: #EFEFF1;
    text-transform: capitalize;
    cursor: pointer;
    letter-spacing: 0.5px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-weight: bold;
  }
  
  .button-navbar-login:hover {
    background: #343843;
  }
  .button-navbar-register {
    background-color: #ed3d3d !important;
    display: flex;
    text-shadow: rgb(0, 0, 0) 0px 1px 0px;
    align-items: center;
    justify-content: center;
    padding: 0px 1rem;
    text-shadow:'0 1px 0 #000';
    height: 3rem;
    border-radius: 0.375rem;
    color: #ededed;
    width: 100%;
    text-transform: capitalize;
    cursor: pointer;
    letter-spacing: 0.5px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    
  }
  
  .button-navbar-register:hover {
    background-color: #ff5555 !important;
  }  
  .navbar-image-avatar:hover{
   background: red !important; 
  }
  .boton-descarga:hover{
    background-color: #ff1007 !important;

  }

  .dropdownaccount-link {
    display: flex;
    height: 100%;
    width: 90%;
    text-decoration: none;
    color: #fff;
    font-weight: 400;
    transition: color 1s ease;
    padding: 15px;
    
  }

  .dropdownaccount-link:hover{
    cursor: pointer;
    background-color: #202329;
    border-radius: 5px;
  }