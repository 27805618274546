@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.dropdownpurchase-menu {
    position: fixed;
    top: 70px;
    display: flex;
    background: #121418;
    border: 1px solid #2a2e38;
    box-shadow: 0px 5px 20px 5px black;
    border-radius: 5px;
    font-family: 'Roboto';
    width: 30%;
}

.dropdownpurchase-menu li {
    display: flex;
    justify-content: space-around;
    width: 95%;
    gap: 15px;
    font-size: 12px;
    padding: 10px 0px;
    margin-top: 5px;
    cursor: pointer;
}


.dropdownpurchase-menu.clicked {
    display: none;
}

.dropdownpurchase-button {
    width: 100px;
    background-color: #f36196;
    border: none;
    font-family: 'Montserrat';
    font-weight: 800;
    padding: 5px;
    color: #ededed;
    cursor: pointer;
    border-radius: 3px;

}

.dropdownpurchase-input {
    width: 100%;
    display: flex;
    align-items: center;
}

.dropdownpurchase-input input {
    width: 90%;
    background-color: #040404;
    border: none;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 5px;
    color: #ededed;
    outline: none;
    margin-left: 1px;
}

.dropdownpurchase-input i {
    background-color: #040404;
    padding: 7px;
    color: #ededed;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.dropdownpurchase-news-card {
    width: 100%;
    height: 100px;
    border-radius: 5px;
    background-color: #151515;
    color: #ededed;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdownpurchase-news-card:hover {
    background-color: #202329;
}


.dropdownpurchase-button-follow {
    width: 50px;
    padding: 5px;
    background-color: #f36196;
    border: none;
    color: #ededed;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
}

@media screen and (max-width: 758px) {

    .dropdownpurchase-menu {
        width: 100%;
        right: 0%;
        

    }

    .fa-caret-down {
        display: none;
    }
}