
@media (min-width: 1200px) and (max-width: 1439px) {
    .menu-informacion{
        width:100% !important;
        display: flex !important;
        padding: 5px 5px;
        background-color: #2a2e38;
        border-radius: 5px;
        gap: 15px
    }
    
}
    @media (min-width: 1920px) {
      .menu-info{
        width: 42% !important;
      }
        .menu-informacion{
            width: 60% !important;
            display: flex !important;
            padding: 5px 5px;
            background-color: #2a2e38;
            border-radius: 5px;
            gap: 15px
        }
    }
    @media (min-width: 1440px) and (max-width: 1919px) {
        .menu-informacion{
            width: 75% !important;
            display: flex !important;
            padding: 5px 5px;
            background-color: #2a2e38;
            border-radius: 5px;
            gap: 15px
        }
    }
   /* Pantallas de Celulares (Hasta 480px) */
@media (max-width: 480px) {
    .usersettings-settings {
        width: 90% !important;
        border: 1px solid rgb(42, 46, 56);
        color: #ededed;
        border-radius: 5px;
        padding: 15px;
        display: flex;
        gap: 10px;
    }
}
.item-config:hover{
    background-color: #cd1009 !important;
    color: white !important;
    border-radius: 5px;
    cursor: pointer;
}
.item-config-active{
    background-color: #343843;
    border-radius: 5px;

}
.item-config{
    color:#a2a9b4 !important;
}
.item-config-active{
  color: #fff !important;
  font-weight: 600;
}

.usersettings-settings {
    width: 100%;
    border: 1px solid rgb(42, 46, 56);
    color: #ededed;
    border-radius: 5px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.usersettings-settings {
    display: flex;
    overflow-x: auto;
    width: 100%;
}
.usersettings-change-avatar {
    width: 100%;
    margin-top: 15px;
}


.menu-rankings::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  .menu-rankings::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  .menu-rankings::-webkit-scrollbar-thumb {
    background: #343843;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  .menu-rankings::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
  .menu-rankings::-webkit-scrollbar-thumb:active {
    background: #343843;
  }
  .menu-rankings::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  .menu-rankings::-webkit-scrollbar-track:hover {
    background: #666666;
  }
  .menu-rankings::-webkit-scrollbar-track:active {
    background: #333333;
  }
  .menu-rankings::-webkit-scrollbar-corner {
    background: transparent;
  }

