.item-config:hover {
    background-color: #343843;
    border-radius: 5px;
    cursor: pointer;
}

.item-config-active {
    background-color: #343843;
    border-radius: 5px;

}

.item-config {
    color: white;
}

.usersettings-settings {
    width: 100%;
    border: 1px solid rgb(42, 46, 56);
    color: #ededed;
    border-radius: 5px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.usersettings-change-avatar {
    width: 100%;
    margin-top: 15px;
}

.download-button {
    background-color: #2a2e38;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 5px;
    padding: 15px 20px;
    /* Ajusta el padding para hacer los botones más grandes */
    margin-right: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    color: white;
    text-decoration: none;
}

.download-button a {
    color: white;
    text-decoration: none;
}

.download-button:hover {
    background-color: #343843;
}

.MuiAlert-icon {
    width: 24px;
    /* Ancho deseado */
    height: 24px;
    /* Altura deseada */
    overflow: visible;
    /* Permitir que el icono se muestre completamente */
}

.MuiAlert-icon svg {
    width: 24px;
    /* Establecer el ancho deseado */
    height: 24px;
    /* Establecer la altura deseada */
    overflow: visible;
    /* Permitir que el icono se muestre completamente */
}